import { toRefs, reactive, computed } from 'vue'

const layoutConfig = reactive({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    menuTheme: 'colorScheme',
    colorScheme: 'light',
    theme: 'indigo',
    scale: 14,
})

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    sidebarActive: false,
    anchored: false,
    overlaySubmenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null,
    editorSidebarActive: false,
    changePasswordModal: false,
    twoFactorAuthModal: false,
    manageTaskModal: false,
})

const layoutDocument = reactive({
    _id: '',
    docID: '',
    ownerId: '',
    title: '',
    content: '',
    updatedAt: 0,
    ingestedAt: 0,
    ingestClassName: '',
    isSavingDoc: -1,
    importMode: false,
    breadcrumbs: [],
    isPublicShare: false,
    allowComment: false,
    allowEdit: false,
    shareIds: [],
})

const layoutEditor = reactive({
    modalOpen: false,
    editorInstance: null,
    saveTextTemplateModalOpen: false,
    loadTextTemplateModalOpen: false,
    createTemplateCategoryModalOpen: false,
    shareModalOpen: false,
    editFolderNameModalOpen: false,
    shareFolderModalOpen: false,
})

export function useLayout() {
    const setScale = (scale: number) => {
        layoutConfig.scale = scale
    }

    const setActiveMenuItem = (item: any) => {
        layoutState.activeMenuItem = item?.value || item
    }

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive =
                !layoutState.staticMenuDesktopInactive
        } else {
            layoutState.staticMenuMobileActive =
                !layoutState.staticMenuMobileActive
        }
    }

    const onProfileSidebarToggle = () => {
        layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible
    }

    const onConfigSidebarToggle = () => {
        layoutState.configSidebarVisible = !layoutState.configSidebarVisible
    }

    const isSidebarActive = computed(
        () =>
            layoutState.overlayMenuActive ||
            layoutState.staticMenuMobileActive ||
            layoutState.overlaySubmenuActive
    )

    const isDarkTheme = computed(() => layoutConfig.theme !== 'light')

    const isDesktop = computed(() => window.innerWidth > 991)

    const isSlim = computed(() => layoutConfig.menuMode === 'slim')
    const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus')

    const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal')

    const toggleEditorSidebar = () => {
        layoutState.editorSidebarActive = !layoutState.editorSidebarActive
    }

    type LayoutDocument = typeof layoutDocument

    const updateLayoutDocument = (obj: Partial<LayoutDocument>) => {
        const keysToCopy: (keyof LayoutDocument)[] = [
            '_id',
            'docID',
            'ownerId',
            'title',
            'content',
            'updatedAt',
            'ingestedAt',
            'ingestClassName',
            'importMode',
            'breadcrumbs',
            'isPublicShare',
            'allowComment',
            'allowComment',
            'shareIds',
        ]

        const sanitizedObject: Record<
            string,
            string | number | boolean | undefined
        > = {}

        keysToCopy.forEach((key) => {
            if (obj?.hasOwnProperty(key) && obj[key]) {
                sanitizedObject[key] = obj[key]
            }
        })

        Object.assign(layoutDocument, sanitizedObject)
    }

    const savingDocLoader = (flag = true) => {
        if (flag) {
            layoutDocument.isSavingDoc = 1
        } else {
            layoutDocument.isSavingDoc = 0
            setTimeout(() => {
                layoutDocument.isSavingDoc = -1
            }, 3000)
        }
    }

    const updateLayoutEditor = (obj: any) => {
        if (obj?.modalOpen === true || obj?.modalOpen === false)
            layoutEditor.modalOpen = obj.modalOpen
        if (obj?.editorInstance)
            layoutEditor.editorInstance = obj.editorInstance
        if (
            obj?.saveTextTemplateModalOpen === true ||
            obj?.saveTextTemplateModalOpen === false
        )
            layoutEditor.saveTextTemplateModalOpen =
                obj.saveTextTemplateModalOpen
        if (
            obj?.loadTextTemplateModalOpen === true ||
            obj?.loadTextTemplateModalOpen === false
        )
            layoutEditor.loadTextTemplateModalOpen =
                obj.loadTextTemplateModalOpen
        if (
            obj?.createTemplateCategoryModalOpen === true ||
            obj?.createTemplateCategoryModalOpen === false
        )
            layoutEditor.createTemplateCategoryModalOpen =
                obj.createTemplateCategoryModalOpen
        if (obj?.shareModalOpen === true || obj?.shareModalOpen === false)
            layoutEditor.shareModalOpen = obj.shareModalOpen
        if (
            obj?.editFolderNameModalOpen === true ||
            obj?.editFolderNameModalOpen === false
        )
            layoutEditor.editFolderNameModalOpen = obj.editFolderNameModalOpen
        if (
            obj?.shareFolderModalOpen === true ||
            obj?.shareFolderModalOpen === false
        )
            layoutEditor.shareFolderModalOpen = obj.shareFolderModalOpen
    }

    const toggleChangePasswordModal = (value: boolean) => {
        layoutState.changePasswordModal = value
    }

    const toggleTwoFactorAuthModal = (value: boolean) => {
        layoutState.twoFactorAuthModal = value
    }

    const toggleManageTaskModal = (value: boolean) => {
        layoutState.manageTaskModal = value
    }

    return {
        layoutConfig: toRefs(layoutConfig),
        layoutState: toRefs(layoutState),
        layoutDocument: toRefs(layoutDocument),
        layoutEditor: toRefs(layoutEditor),
        setScale,
        onMenuToggle,
        isSidebarActive,
        isDarkTheme,
        setActiveMenuItem,
        onProfileSidebarToggle,
        onConfigSidebarToggle,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop,
        toggleEditorSidebar,
        updateLayoutDocument,
        savingDocLoader,
        updateLayoutEditor,
        toggleChangePasswordModal,
        toggleTwoFactorAuthModal,
        toggleManageTaskModal,
    }
}
